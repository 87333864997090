<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
    append-to-body
    >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>选择收费课程</span>
    </div>
    <el-table
      ref="courseList"
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="名称" align="center" prop="productName"></el-table-column>
      <el-table-column label="单位" align="center" prop="costUnit"></el-table-column>
      <el-table-column label="选择价格" align="center">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.priceid"
            placeholder="请选择价格方案"
            @change="handleChange(scope.row)"
            class="tb-select">
            <el-option
              v-for="(item, index) in scope.row.productPriceBeans"
              :key="index"
              :label="item.priceName"
              :value="item.id"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
        <el-button type="primary" size="mini" v-if="!scope.row.isSelect" @click="handleSelectionChange(scope.row)">选择</el-button>
          <el-button type="success" size="mini" v-else>已选择</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
    <el-row slot="footer">
      <el-button type="primary" @click="confirm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getProductList } from '@/api/system/course'
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    chooseDataList: {
      default: ()=> {
        return []
      },
      type: Array
    },

  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      courseTitle: '',
      formData: {},
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      multipleSelection: null,
      currentNum:0
    }
  },
  methods: {
    handleChange(row) {
      this.multipleSelection.priceid = row.priceid
      this.currentNum ++
      if(this.currentNum % 2 == 0) {
        this.$refs.courseList.setCurrentRow();
      }else {
        this.$refs.courseList.setCurrentRow(this.dataList[0]);
      }
    },
    openDialog () {
      this.currentNum = 0
      if(this.chooseDataList.length === 0){
        this.multipleSelection = null
      }
      this.getProductList()
    },

    handleSelectionChange (val) {
      this.multipleSelection = val

      this.dataList.forEach(item => {
        if(item.id === this.multipleSelection.id) {
          item.isSelect = true
        }else {
          item.priceid = undefined
          item.isSelect = false
        }
      })
      this.currentNum ++
      if(this.currentNum % 2 == 0) {
        this.$refs.courseList.setCurrentRow();
      }else {
        this.$refs.courseList.setCurrentRow(this.dataList[0]);
      }
    },

    async getProductList () {
      const res = await getProductList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true
      })

      this.dataList = res.body.list
      this.pagination.total = res.body.total

      for (let i = 0; i < this.dataList.length; i++) {
        this.dataList[i].isSelect = false
        for (let j = 0; j < this.chooseDataList.length; j++) {
          if(this.dataList[i].id === this.chooseDataList[j].productId) {
            this.dataList[i].priceid = this.chooseDataList[j].id
            this.dataList[i].isSelect = true
          }
        }
      }
      if(this.chooseDataList.length !== 0) {
        this.multipleSelection = this.chooseDataList[0]
        this.multipleSelection.priceid = this.chooseDataList[0].id
      }
    },
    // 关闭
    close () {
      this.$emit('close')
    },

    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getProductList()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getProductList()
    },

    confirm () {
      if (this.multipleSelection === null) {
        window.$msg('无勾选数据', 2)
      } else {
        if(this.multipleSelection.priceid === undefined || this.multipleSelection.priceid === null) {
          $msg("【"+this.multipleSelection.productName+"】没有选择价格方案",2)
          return false
        }
        let lastDataList = []
        this.dataList.forEach(item => {
          item.productPriceBeans.forEach((childItem,childIndex) => {
            if(childItem.id === this.multipleSelection.priceid) {
              childItem.productName = this.multipleSelection.productName
              childItem.priceid  = this.multipleSelection.priceid
              lastDataList.push(childItem)
            }
          })
        })

        lastDataList.forEach((item) => {
          item.themboriginalPrice = item.originalPrice
          if(item.isTerm === "1") {
            item.settermAmount = 1
            item.thembtermAmount = item.termAmount
          }else if(item.isTerm === "2") {
            if(item.termAmount === null || item.termAmount === 0) {
              item.settermAmount = 1
              item.thembtermAmount = 1
            }else {
              item.settermAmount = item.termAmount
              item.thembtermAmount = item.termAmount
            }
          }
        })
        this.$emit('saveCourse', lastDataList)
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../style/dialog.scss';
</style>
