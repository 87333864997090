<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="70%"
    @open="openDialog"
    top="7vh"
    >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="courseTitle"></span>
    </div>
    <!-- dataList: {{dataList}}
    <hr>
    sellCourseData: {{sellCourseData}} -->
    <el-form ref="formData" :model="formData" label-position="left" :rules="rules" label-width="100px">
      <el-form-item label="名称：" prop="groupName">
        <el-input v-model="formData.groupName" placeholder="请输入销售课程包名称"></el-input>
      </el-form-item>
      <el-form-item label="收费课程：" prop="bank">
        <span style="cursor: pointer;" @click="seleSellCourse">
          <i class="el-icon-s-operation"></i>
          选择收费课程
        </span>
        <el-table
          style="border-top: 1px solid #EBEEF5;border-left: 1px solid #EBEEF5;margin-top: 25px"
          :data="dataList"
          border
          fit
          highlight-current-row
        >
          <el-table-column align="center" label="名称" prop="productName"></el-table-column>
          <el-table-column align="center" label="价格方案" prop="priceName"></el-table-column>
          <el-table-column align="center" label="设置数量">
            <template slot-scope="scope">
              <el-row style="display: flex;align-items: center">
                <el-col :span="18">
                  <el-input-number style="width: 100%;" :precision="0" v-model="scope.row.settermAmount" :min="1" :controls="false" @change="handleChange"></el-input-number>
                </el-col>
                <el-col :span="6"><span v-if="scope.row.isTerm == 1">期</span><span v-if="scope.row.isTerm == 2">课时</span></el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column align="center" label="课时数">
            <template slot-scope="scope">
              <span>{{scope.row.thembtermAmount}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="原价" >
            <template slot-scope="scope">
              ￥{{scope.row.originalPrice}}
            </template>

          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="delSellCourse(scope.row, scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item>
        <el-row style="line-height: 35px">
          <p>原价合计：￥{{discountsPrice}}</p>
          <p style="margin-top: 15px;">最低价：
            ￥<el-input-number style="width: 10%;" :precision="2" v-model="lowPrice" :min="1" :controls="false" @change="handleChange"></el-input-number>
          </p>
        </el-row>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-button type="primary" @click="saveRoleForm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>

    <!--  -->
    <sele-sell-course :show="courseDialog" :chooseDataList="dataList" @close="courseDialog=false" @saveCourse="saveCourse"></sele-sell-course>
  </el-dialog>
</template>

<script>
import seleSellCourse from '@/components/system/seleSellCourse'
import { addProductGroup, getProductListByGroup,editProductGroup } from '@/api/system/course'

export default {
  components: { seleSellCourse },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    sellCourseData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      courseTitle: '',
      formData: {},
      rules: {
        groupName: [{ required: true, message: '请输入销售课程包名称', trigger: 'blur' }],
      },
      dataList: [],
      lowPrice: 0,
      discountsPrice: 0,
      courseDialog: false,
      moneyNum:""
    }
  },
  methods: {
    // 计算出销售价格、优惠价格
    calculateSellPrice () {
      this.discountsPrice = 0
      this.dataList.forEach((item) => {
        this.discountsPrice += Boolean(item.originalPrice) ? Number(item.originalPrice) : 0
      })
    },

    openDialog () {
      this.formData = {
        "groupName":"",
        "id":""
      }
      this.moneyNum = ""
      this.dataList = []
      this.lowPrice = 0
      this.discountsPrice = 0
      if (this.sellCourseData === null) {
        this.courseTitle = '新增销售课程包'
      } else {
        this.courseTitle = '编辑销售课程包'
        this.lowPrice = this.sellCourseData.lowPrice
        this.discountsPrice = this.sellCourseData.originalPrice
        this.formData.groupName = this.sellCourseData.groupName
        this.formData.id =this.sellCourseData.id
        this.getProductListByGroup()
        // this.formData = Object.assign({}, this.sellCourseData)
      }
    },
    getProductListByGroup () {
      getProductListByGroup({
        groupId: this.sellCourseData.id
      }).then(res => {
        this.dataList = []
        res.body.forEach((item)=> {
          item.productPriceMap.settermAmount = item.amount
          this.dataList.push(item.productPriceMap)
        })
        this.dataList.forEach((item) => {
          item.id = item.priceId
          item.themboriginalPrice = item.originalPrice
          if(item.isTerm === 1) {
            item.thembtermAmount = item.termAmount * item.settermAmount
          }else if(item.isTerm === 2) {
            item.thembtermAmount = item.settermAmount
          }
          item.originalPrice = item.themboriginalPrice * item.settermAmount
        })
        console.log("接口this.DataList=======>" + JSON.stringify(this.dataList))
      })
    },

    handleChange (value) {
      this.dataList.forEach((item) => {
        if(item.isTerm == 1) {
          item.thembtermAmount = item.termAmount * item.settermAmount
        }else if(item.isTerm == 2) {
          item.thembtermAmount = item.settermAmount
        }
        item.originalPrice = item.themboriginalPrice * item.settermAmount
      })
      this.calculateSellPrice()
    },
    // 关闭
    close () {
      this.$emit('close')
    },

    seleSellCourse () {
      console.log("this.dataList-----"+JSON.stringify(this.dataList))
      this.courseDialog = true
    },

    delSellCourse (row, idx) {
      this.dataList.splice(idx, 1)
      if(this.dataList.length === 0) {
        this.lowPrice = 0
      }
      this.calculateSellPrice()
    },
    saveRoleForm () {
      this.$refs.formData.validate(valid => {
        if (valid) {

          let dataObject = {}
          if(this.dataList.length === 0){
            window.$msg("请选择课程后在保存",2)
            return false
          }else{
            var thembDataList = []
            this.dataList.forEach((item) => {
              var thembitem = {}
              thembitem.productId = item.productId
              thembitem.priceId = item.id
              thembitem.amount = item.settermAmount
              thembDataList.push(thembitem)
            })
            dataObject.groupName = this.formData.groupName
            dataObject.lowPrice = this.lowPrice
            dataObject.productGroupInfoList = thembDataList
          }

          if (this.sellCourseData === null) {
            addProductGroup(dataObject).then(res => {
              if (res.state === 'success') {
                window.$msg('新增成功')
                this.close()
                this.$emit('updateView')
              }
            })
          } else {
            dataObject.id = this.formData.id
            editProductGroup(dataObject).then(res => {
              if (res.state === 'success') {
                window.$msg('编辑成功')
                this.close()
                this.$emit('updateView')
              }
            })
          }
        }
      })
    },

    confirmActualPriceEdit (row) {
      this.calculateSellPrice()
    },

    saveCourse (data) {
      this.dataList = []
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.dataList.length ; j++) {
          if(this.dataList[j].productId === data[i].productId) {
            data.splice(i,1,this.dataList[j]) //修改 a=[1,2,'a']
          }
        }
      }
      this.dataList = data
      this.dataList.forEach((item) => {
        item.settermAmount = 1
        item.themboriginalPrice = item.originalPrice
        if(item.isTerm === 1) {
          item.thembtermAmount = item.termAmount * item.settermAmount
        }else if(item.isTerm === 2) {
          item.thembtermAmount = item.settermAmount
        }
        item.originalPrice = item.themboriginalPrice * item.settermAmount
      })
      this.calculateSellPrice()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../style/dialog.scss';
// /deep/ .el-input-number--medium {
//   width: 115px;
// }
</style>
