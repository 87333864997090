<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    append-to-body
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>编辑多科优惠</span>
    </div>
    <el-form ref="muchCourseData" :model="muchCourseData" label-width="120px">
      <el-form-item label="活动名称：">
        <el-input disabled v-model="muchCourseData.groupName"></el-input>
      </el-form-item>
      <el-form-item label="结果 折扣：">
        <el-input-number style="width: 150px;" v-model="muchCourseData.discount"></el-input-number><span style="margin-left: 20px;">%</span>
      </el-form-item>
      <el-form-item label="授权课程：">
        <el-tag
          v-for="tag in tags"
          :key="tag.id"
          closable
          @close="handleClose(tag)"
          :type="tag.type">
          {{tag.productName}}
        </el-tag>
      </el-form-item>
      <el-form-item label="选择课程：">
        <el-select
          style="width: 250px"
          v-model="selectCourse"
          multiple
          collapse-tags
          @change = "handleSelectChange"
          placeholder="请选择授权课程">
          <el-option
            v-for="item in dataList"
            :key="item.id"
            :label="item.productName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存编辑</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  import {getGrantProduct,grantProduct,ungrantProduct,getProductList,editProductGroup} from '@/api/system/course'
  export default {
    props: {
      show: {
        default: false,
        type: Boolean
      },
      muchCourseData: {
        default: () => {
          return {}
        },
        type: Object
      }
    },
    data () {
      return {
        dataList: [],
        tags: [],
        selectCourse:[],
        thembSelectCourse:[],
      }
    },
    methods: {
      async onSubmit() {
        const response = await editProductGroup({
          id:this.muchCourseData.id,
          discount:this.muchCourseData.discount
        })
        if(response && response.state === "success") {
          $msg("编辑成功")
          this.$emit('close')
          this.$emit('updatepage')
        }
      },
      getArrDifference(arr1, arr2) {
        return arr1.concat(arr2).filter(function(v, i, arr) {
          return arr.indexOf(v) === arr.lastIndexOf(v);
        });
      },
      handleSelectChange(row) {
        console.log(JSON.stringify(row))
        console.log(JSON.stringify(this.thembSelectCourse))
        if(row.length > this.thembSelectCourse.length) {
          var thembarray = this.getArrDifference(row,this.thembSelectCourse)
          this.grantProduct(thembarray[0])
        }else if(row.length < this.thembSelectCourse.length) {
          var thembarray = this.getArrDifference(row,this.thembSelectCourse)
          this.dataList.forEach(item => {
            if(item.id === thembarray[0]) {
              this.ungrantProduct(item.pggId)
            }
          })
        }

        this.thembSelectCourse = this.selectCourse
        this.tags = []
        this.dataList.forEach(item => {
          row.forEach(value=> {
            if(item.id == value) {
              this.tags.push(item)
            }
          })
        })
      },
      handleClose(tag) {
        this.dataList.forEach(item => {
          if(item.id === tag.id) {
            this.ungrantProduct(item.pggId)
          }
        })
        this.tags.forEach((item,index)=>{
          if (item.id === tag.id) {
            this.tags.splice(index,1)
            this.selectCourse.splice(index,1)
          }
        })
      },
      async grantProduct(id) {
        const response = await grantProduct({
          groupId:this.muchCourseData.id,
          productId:id
        })
        if(response && response.state === "success") {
          $msg("授权成功")
          this.dataList.forEach(item=> {
            if(id == item.id) {
              item.pggId = response.body
            }
          })
        }
      },
      async ungrantProduct(pggId) {
        const response = await ungrantProduct({
          pggId:pggId,
        })
        if(response && response.state === "success") {
          $msg("取消授权成功")
        }
      },
      async getGrantProduct() {
        const response = await getGrantProduct({
          groupId:this.muchCourseData.id
        })
        response.body.forEach( item=>{
          this.selectCourse.push(item.productId)
          this.thembSelectCourse.push(item.productId)
          this.dataList.forEach(value => {
            if(item.productId === value.id) {
              value.pggId = item.pggId
              this.tags.push(value)
            }
          })
        })
      },
      async getProductList() {
        const response = await getProductList(
          {
            pageNum: 1,
            pageSize: 10000,
            needCount: true,
          })
        this.dataList = response.body.list
        this.dataList.forEach(item => {
          item.type = 'info'
        })
        this.getGrantProduct()
      },

      openDialog () {
        this.selectCourse = []
        this.tags = []
        this.thembSelectCourse = []
        this.getProductList()
      },
      close () {
        this.$emit('close')
      },
      async queryDataList () {
        const res = await getProductUserInf({
          puId:this.puId
        })
        this.dataList = res.body
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../style/dialog.scss";
  @import "../../../assets/font/iconfont.css";
  .centerCls {
    display: flex;
    align-items: center
  }
  p {
    line-height: 30px;
  }
</style>
