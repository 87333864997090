<template>
  <el-row class="table">
    <span class="title" style="padding-left: 0px;">收费课程包管理</span>
      <el-tabs style="margin-top: 20px;" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="单科管理" name="first">
    <el-row class="row-bg">
      <el-col :span="2"> <el-input v-model="groupName" placeholder="名称关键字"></el-input></el-col>
      <el-select v-model="stuType" placeholder="请选择学员类型" style="margin-left: 20px;" class="tb-smselect">
        <el-option
          v-for="item in stuOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button type="primary" @click="search" class="tb-button">查询</el-button>
      <el-button type="primary" @click="add" class="tb-button">新增</el-button>
    </el-row>
    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="名称" align="center" prop="groupName"></el-table-column>
      <el-table-column label="原价" align="center">
        <template slot-scope="scope">
          <span>￥{{scope.row.originalPrice}}</span>
        </template>
      </el-table-column>
      <el-table-column label="最低价" align="center">
        <template slot-scope="scope">
          <span>￥{{scope.row.lowPrice}}</span>
        </template>
      </el-table-column>
      <el-table-column label="包含课时" align="center" prop="infolist"></el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <span v-text="getGroupText(scope.row.status)" :style="'color'+':'+getGroupColor(scope.row.status)"></span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="grantCampus(scope.row)">授权校区</el-button>
          <el-button type="text" @click="start(scope.row)" v-if="scope.row.status === '2'">启 用</el-button>
          <el-button type="text" @click="disable(scope.row)" v-if="scope.row.status === '1'">禁 用</el-button>
          <el-button type="text" @click="edit(scope.row)">编 辑</el-button>
          <el-button type="text" @click="del(scope.row)">删 除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
    <!--  -->
    <add-sell-course :show="sellCourseDialog" @close="sellCourseDialog=false;sellCourseData=null" :sellCourseData="sellCourseData" @updateView="getProductGroupList"></add-sell-course>
        </el-tab-pane>
        <el-tab-pane label="多科管理" name="second">
          <el-table
            :data="datamuchList"
            border
            fit
            highlight-current-row
            class="tb-list"
          >
            <el-table-column label="名称" align="center" prop="groupName"></el-table-column>
            <el-table-column label="结果折扣" align="center" prop="discount"></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="grantCampus(scope.row)">授权校区</el-button>
                <el-button type="text" @click="muchedit(scope.row)">编 辑</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeMuchChange"
            @current-change="handleCurrentMuchChange"
            :current-page="paginationmuch.currentPage"
            :page-sizes="paginationmuch.pageSizes"
            :page-size="paginationmuch.pageSize"
            :layout="paginationmuch.layout"
            :total="paginationmuch.total"
          ></el-pagination>
          <edit-much-course :show="showmuchCourseDialog" @updatepage = "getProductMuchGroupList"  @close="showmuchCourseDialog=false" :muchCourseData="muchCourseData"></edit-much-course>

        </el-tab-pane>
      </el-tabs>
    <campuslist :show="showcampuslistDialog"  @close="showcampuslistDialog=false" :selectcampusData="selectcampusData"></campuslist>
  </el-row>
</template>

<script>
import addSellCourse from '@/components/system/addSellCourse'
import editMuchCourse from '@/components/system/editMuchCourse'
import campuslist from '@/components/system/campuslist'
import { getProductGroupList, deletProductGroup, updateStatus } from '@/api/system/course'
import getGroupText from '@/mixins/getStateText'
import getGroupColor from '@/mixins/getStateColor'

export default {
  mixins: [getGroupText, getGroupColor],
  components: {
    addSellCourse,
    editMuchCourse,
    campuslist
  },
  created () {
    this.getProductGroupList()
    this.getProductMuchGroupList()
  },
  data () {
    return {
      dataList: [ ],
      activeName:"first",
      sellCourseDialog: false,
      showcampuslistDialog:false,
      selectcampusData:{},
      sellCourseData: null,
      stuType:null,
      groupName:"",
      stuOptions: [
        {
          label: '全部',
          value: null
        },
        {
          label: '禁用',
          value: 2
        },
        {
          label: '启用',
          value: 1
        }
      ],
      datamuchList:[],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      paginationmuch: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      showmuchCourseDialog:false,
      muchCourseData:{}
    }
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name
    },
    grantCampus(row) {
      this.showcampuslistDialog = true
      this.selectcampusData = row
    },
    async getProductGroupList () {
      const res = await getProductGroupList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        type:"01",
        groupName:this.groupName,
        status:this.stuType
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },
    async getProductMuchGroupList () {
      const res = await getProductGroupList({
        pageNum: this.paginationmuch.currentPage,
        pageSize: this.paginationmuch.pageSize,
        needCount: true,
        type:"02",
      })
      this.datamuchList = res.body.list
      this.paginationmuch.total = res.body.total
    },
    search () {
      this.pagination.currentPage = 1
      this.getProductGroupList()
    },
    add () {
      this.sellCourseDialog = true
    },
    muchedit(row) {
      this.muchCourseData = row
      this.showmuchCourseDialog = true
    },
    edit (row) {
      this.sellCourseData = row
      this.sellCourseDialog = true
    },

    del (row) {
      console.log('del', row)
      this.$confirm('是否删除当前课程', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await deletProductGroup({
            groupId: row.id
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('删除成功')
              this.getProductGroupList()
            }
          })
        })
    },

    start (row) {
      updateStatus({
        id: row.id,
        status: '1'
      }).then(res => {
        window.$msg('启用成功')
        this.getProductGroupList()
      })
    },

    disable (row) {
      updateStatus({
        id: row.id,
        status: '2'
      }).then(res => {
        window.$msg('禁用成功')
        this.getProductGroupList()
      })
    },


    // 分页
    handleSizeMuchChange (val) {
        this.paginationmuch.pageSize = val
        this.getProductMuchGroupList()
      },
    handleCurrentMuchChange (val) {
        this.paginationmuch.currentPage = val
        this.getProductMuchGroupList()
      },
    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getProductGroupList()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getProductGroupList()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../style/table.scss';
</style>
