var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c(
        "span",
        { staticClass: "title", staticStyle: { "padding-left": "0px" } },
        [_vm._v("收费课程包管理")]
      ),
      _c(
        "el-tabs",
        {
          staticStyle: { "margin-top": "20px" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "单科管理", name: "first" } },
            [
              _c(
                "el-row",
                { staticClass: "row-bg" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "名称关键字" },
                        model: {
                          value: _vm.groupName,
                          callback: function ($$v) {
                            _vm.groupName = $$v
                          },
                          expression: "groupName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticClass: "tb-smselect",
                      staticStyle: { "margin-left": "20px" },
                      attrs: { placeholder: "请选择学员类型" },
                      model: {
                        value: _vm.stuType,
                        callback: function ($$v) {
                          _vm.stuType = $$v
                        },
                        expression: "stuType",
                      },
                    },
                    _vm._l(_vm.stuOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "tb-button",
                      attrs: { type: "primary" },
                      on: { click: _vm.search },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "tb-button",
                      attrs: { type: "primary" },
                      on: { click: _vm.add },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticClass: "tb-list",
                  attrs: {
                    data: _vm.dataList,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "名称",
                      align: "center",
                      prop: "groupName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "原价", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v("￥" + _vm._s(scope.row.originalPrice)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "最低价", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v("￥" + _vm._s(scope.row.lowPrice)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "包含课时",
                      align: "center",
                      prop: "infolist",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "状态", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", {
                              style:
                                "color" +
                                ":" +
                                _vm.getGroupColor(scope.row.status),
                              domProps: {
                                textContent: _vm._s(
                                  _vm.getGroupText(scope.row.status)
                                ),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.grantCampus(scope.row)
                                  },
                                },
                              },
                              [_vm._v("授权校区")]
                            ),
                            scope.row.status === "2"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.start(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("启 用")]
                                )
                              : _vm._e(),
                            scope.row.status === "1"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.disable(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("禁 用")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编 辑")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.del(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删 除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.pagination.currentPage,
                  "page-sizes": _vm.pagination.pageSizes,
                  "page-size": _vm.pagination.pageSize,
                  layout: _vm.pagination.layout,
                  total: _vm.pagination.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
              _c("add-sell-course", {
                attrs: {
                  show: _vm.sellCourseDialog,
                  sellCourseData: _vm.sellCourseData,
                },
                on: {
                  close: function ($event) {
                    _vm.sellCourseDialog = false
                    _vm.sellCourseData = null
                  },
                  updateView: _vm.getProductGroupList,
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "多科管理", name: "second" } },
            [
              _c(
                "el-table",
                {
                  staticClass: "tb-list",
                  attrs: {
                    data: _vm.datamuchList,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "名称",
                      align: "center",
                      prop: "groupName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "结果折扣",
                      align: "center",
                      prop: "discount",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.grantCampus(scope.row)
                                  },
                                },
                              },
                              [_vm._v("授权校区")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.muchedit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编 辑")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.paginationmuch.currentPage,
                  "page-sizes": _vm.paginationmuch.pageSizes,
                  "page-size": _vm.paginationmuch.pageSize,
                  layout: _vm.paginationmuch.layout,
                  total: _vm.paginationmuch.total,
                },
                on: {
                  "size-change": _vm.handleSizeMuchChange,
                  "current-change": _vm.handleCurrentMuchChange,
                },
              }),
              _c("edit-much-course", {
                attrs: {
                  show: _vm.showmuchCourseDialog,
                  muchCourseData: _vm.muchCourseData,
                },
                on: {
                  updatepage: _vm.getProductMuchGroupList,
                  close: function ($event) {
                    _vm.showmuchCourseDialog = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("campuslist", {
        attrs: {
          show: _vm.showcampuslistDialog,
          selectcampusData: _vm.selectcampusData,
        },
        on: {
          close: function ($event) {
            _vm.showcampuslistDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }