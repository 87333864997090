<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span style="font-size: 16px;">授权校区</span>
    </div>
    <div style="display: flex;">
      <div style="width:60%;">
        <el-table
          ref="multipleTable"
          :data="tableData3"
          tooltip-effect="dark"
          border
          style="width:100%;max-height:400px;overflow-y: auto">
          <el-table-column label="校区名称"  align="center" prop="name">
            <template slot-scope="scope">
              {{scope.row.name}}
            </template>
          </el-table-column>
          <el-table-column label="操作"  align="center">
            <template slot-scope="scope">
              <el-button v-if="!scope.row.isSelect" type="primary" size="mini" @click="chooseItem(scope.row)">选择</el-button>
              <el-button v-if="scope.row.isSelect" type="success" size="mini" @click="chooseItem(scope.row)">已选择</el-button>
<!--              <el-button type="success" size="mini"  v-else>已选择</el-button>-->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import { getMyCampus} from '@/api/frontbusiness'
  import { grantCampus,ungrantCampus,getGrantCampus} from '@/api/system/course'
  export default {

    props: {
      show: {
        default: false,
        type: Boolean
      },
      selectcampusData:{
        default: {},
        type:Object
      },
    },
    data () {
      return {
        tableData3: [],
        thembDataList: [],
      }
    },
    methods: {
      chooseItem(row){
        if(!row.isSelect) {
          this.grantCampus(row)
        }else {
          this.ungrantCampus(row)
        }

      },

      openDialog () {
        this.getGrantCampus()
      },
      async getMyCampus() {
        const response = await getMyCampus({})

        this.tableData3 = this.getLastDataList(response.body,this.thembDataList)
      },

      async getGrantCampus() {
        const response = await getGrantCampus( {
          groupId:this.selectcampusData.id,
        })
        this.thembDataList =  response.body
        this.getMyCampus()
      },

      getLastDataList(originDataList,selectDataList) {
        originDataList.forEach((originItem)=> {
          originItem.isSelect = false
        })

        selectDataList.forEach((selectItem)=> {
          originDataList.forEach((originItem)=> {
            if(originItem.id === selectItem.campusId) {
              originItem.isSelect = true
              originItem.grandId = selectItem.id
            }
          })
        })
        console.log("originDataList"+JSON.stringify(originDataList))
        return originDataList
      },

      async ungrantCampus(row) {
        const response = await ungrantCampus( {
          grandId:row.grandId
        })
        if(response && response.state === 'success') {
          $msg("取消授权成功",0);
          this.getGrantCampus()
        }
      },
      async grantCampus(row) {
        const response = await grantCampus( {
          groupId:this.selectcampusData.id,
          campusId:row.id
        })
        if(response && response.state === 'success') {
          $msg("授权成功",0);
          this.getGrantCampus()
        }
      },

      close () { this.$emit('close') },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../style/dialog.scss";
  @import "../../../assets/font/iconfont.css";
  .centerCls {
    display: flex;
    align-items: center
  }
  p {
    line-height: 30px;
  }
</style>
